import React from 'react'
import './Home.css'
import parallaxCollage from '../assets/gur-collage.png'
import sugarcane from '../assets/sugarcane.png'
import logo from '../assets/logo-transparent.png'
import jaggeryPowderSachet from '../assets/jaggery-powder-sachets.jpg'
import jaggeryCubes from '../assets/Cubes1.jpeg'
import jaggeryBlock from '../assets/jaggery-block.jpg'
import mustardJaggery from '../assets/mustard-jaggery.jfif'
import jaggeryPowder from '../assets/jaggery-powder.jpg'
import plainJaggery from '../assets/G2.JPG'


import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from 'react-router-dom'

function Home() {
    console.log(window.location.pathname);
    // if (window.location.pathname == "/") {
    //     (function () {
    //         const safeToAnimate = window.matchMedia('(prefers-reduced-motion: no-preference)').matches;

    //         if (!safeToAnimate) return;
    //         let xPosition;
    //         let yPosition;

    //         let storedXPosition;
    //         let storedYPosition;

    //         let x;
    //         let y;

    //         let windowHeight = window.innerHeight;
    //         let windowWidth = window.innerWidth;

    //         function percentage(partialValue, totalValue) {
    //             return (100 * partialValue) / totalValue;
    //         }

    //         // update the CSS vars within request animation frame
    //         function movePointer() {
    //             window.requestAnimationFrame(movePointer);

    //             // important, only recalculating if the value changes
    //             if (storedXPosition === xPosition && storedYPosition === yPosition) return;

    //             // shift the range from 0 to 100 to -50 to 50 to keep the movement centralised
    //             x = percentage(xPosition, windowWidth) - 50;
    //             y = percentage(yPosition, windowHeight) - 50;

    //             // log the values out to the screen
    //             if (x && y) {
    //                 // console.log(x + " " + y);
    //                 // update the css vars
    //                 document.getElementById("parallax-img").style.transform = `translate(${Math.round(x) / 10}%, ${Math.round(y) / 10}%)`;
    //                 // document.getElementById("parallax-img").style.transform = `translateY(${Math.round(y)/10}%)`;
    //             }

    //             // update the stored positions with the current positions
    //             storedXPosition = xPosition;
    //             storedYPosition = yPosition;
    //         }
    //         window.requestAnimationFrame(movePointer);

    //         // updating the mouse coordinates
    //         function updateMouseCoords(event) {
    //             xPosition = event.clientX;
    //             yPosition = event.clientY;
    //         }
    //         window.addEventListener("mousemove", updateMouseCoords);

    //         // update if browser resizes
    //         function updateWindowSize() {
    //             windowHeight = window.innerHeight;
    //             windowWidth = window.innerWidth;
    //         }
    //         window.addEventListener("resize", updateWindowSize);
    //     })();
    // }

    return (
        <div className='page-container'>
            <div class="home-container">
                <section class="curved section-1">
                    <div class="gradient-overlay">
                        <div class="home-info">
                            <div class="tagline">
                                <ScrollAnimation animateIn='fadeIn' className='tagline-logo'>
                                    <img src={logo} className='tagline-logo' alt="" />
                                </ScrollAnimation>
                                <h1>Connecting you back to your roots</h1>
                                <h3>A brand name in purity...</h3>
                            </div>
                        </div>
                        <div class="parallax-img">
                            <img id="parallax-img" src={parallaxCollage} alt="" />
                        </div>
                    </div>
                </section>
                <section class="section-2">
                    <div className="products-section">
                        <h1>OUR PRODUCTS</h1>
                        <section className='product-1'>
                            <ScrollAnimation animateIn='bounceInLeft'>
                                <img src={plainJaggery} alt="" />
                            </ScrollAnimation>
                            <div class="product-info">
                                <h2>PLAIN JAGGERY BLOCKS</h2>
                                <p>Authentic plain jaggery in the form of blocks. It is processed unrefined so as to preserve the necessary nutrients. This is what makes brown jaggery a more recommended substitute as compared to white sugar. We provide you with 1kg packages containing 10-12 pieces each.</p>
                            </div>
                            <div class="shop-now">
                                <button class="learn-more btn">
                                    <span class="circle" aria-hidden="true">
                                        <span class="icon arrow"></span>
                                    </span>
                                    <a target="_blank" href="https://www.amazon.in/VEDNUTRI-SUGARCANE-JAGGERY-NATURAL-SWEETENER/dp/B08W8JQ3SH"><span class="button-text">Shop Now</span></a>
                                </button>
                            </div>
                        </section>
                        <hr className='style-two' />
                        <ScrollAnimation animateIn='fadeIn'>
                            <section className='product-2'>
                                <ScrollAnimation animateIn='bounceInRight'>
                                    <img src={jaggeryBlock} alt="" />
                                </ScrollAnimation>
                                <div class="product-info">
                                    <h2> MASALA JAGGERY BLOCKS</h2>
                                    <p>Masala Jaggery - A way to enrich your jaggery with fiber and vitamins thereby enhancing energy levels and digestion. The addition of crushed peanuts, cashew nuts, walnuts, ginger powder, fennel seeds(saunf), sesame(til), and carom seeds(ajwain) would increase your nutrient intake. We provide you with 1kg packages containing 10-12 pieces each.
</p>
                                </div>
                                <div class="shop-now">
                                    <button class="learn-more btn">
                                        <span class="circle" aria-hidden="true">
                                            <span class="icon arrow"></span>
                                        </span>
                                        <a target="_blank" href="https://www.amazon.in/VEDNUTRI-SUGARCANE-JAGGERY-NATURAL-SWEETENER/dp/B09R811WVG"><span class="button-text">Shop Now</span></a>
                                    </button>
                                </div>
                            </section>
                        </ScrollAnimation>
                        <hr className='style-two' />
                        <ScrollAnimation animateIn='fadeIn'>
                            <section className='product-3'>
                                <ScrollAnimation animateIn='bounceInLeft'>
                                    <img src={jaggeryCubes} alt="" />
                                </ScrollAnimation>
                                <div class="product-info">
                                    <h2>PLAIN JAGGERY CUBES</h2>
                                    <p>These are attractive cube-shaped jaggery, absolutely delicious, and easy to eat. We provide you with easy-to-use packaging. In its 1 kg packing, you will be provided with 60-65 cubes. Have a cube every time you wish to have something for dessert after your meal !

</p>
                                </div>
                                <div class="shop-now">
                                    <button class="learn-more btn">
                                        <span class="circle" aria-hidden="true">
                                            <span class="icon arrow"></span>
                                        </span>
                                        <a target="_blank" href="https://www.amazon.in/VEDNUTRI-SUGARCANE-JAGGERY-NATURAL-SWEETENER/dp/B09P85K6Q4"><span class="button-text">Shop Now</span></a>
                                    </button>
                                </div>
                            </section>
                        </ScrollAnimation>
                        <hr className='style-two' />
                        <ScrollAnimation animateIn='fadeIn'>
                            <section className='product-4'>
                                <ScrollAnimation animateIn='bounceInRight'>
                                    <img src={mustardJaggery} alt="" />
                                </ScrollAnimation>
                                <div class="product-info">
                                    <h2>MASALA JAGGERY CUBES</h2>
                                    <p> If you wish to have easy-to-eat cube-shaped jaggery along with a good portion of nutrients then you should definitely try our masala cube jaggery. Herein you will find crushed peanuts, cashew nuts, walnuts, ginger powder, fennel seeds(saunf), sesame(til), and carom seeds(ajwain). Its 1 kg packing consists of 60-65 cubes each.
</p>
                                </div>
                                <div class="shop-now">
                                    <button class="learn-more btn">
                                        <span class="circle" aria-hidden="true">
                                            <span class="icon arrow"></span>
                                        </span>
                                        <a target="_blank" href="https://www.amazon.in/VEDNUTRI-SUGARCANE-JAGGERY-NATURAL-SWEETENER/dp/B09R81RQV6"><span class="button-text">Shop Now</span></a>
                                    </button>
                                </div>
                            </section>
                        </ScrollAnimation>
                        <hr className='style-two' />
                        <ScrollAnimation animateIn='fadeIn'>
                            <section className='product-5'>
                                <ScrollAnimation animateIn='bounceInLeft'>
                                    <img src={jaggeryPowder} alt="" />
                                </ScrollAnimation>
                                <div class="product-info">
                                    <h2>JAGGERY POWDER</h2>
                                    <p>Powdered Jaggery, commonly known as SHAKKAR, is the recommended substitute for white sugar. Unlike refined sugar coming from chemical processing, jaggery powder retains trace minerals and vitamins. Hence it is a healthy and nutrient-rich sweetener. Try this natural health booster rich in magnesium, zinc, and iron.</p>
                                </div>
                                <div class="shop-now">
                                    <button class="learn-more btn">
                                        <span class="circle" aria-hidden="true">
                                            <span class="icon arrow"></span>
                                        </span>
                                        <a target="_blank" href="https://www.amazon.in/VEDNUTRI-SUGARCANE-JAGGERY-SWEETENER-Packaging/dp/B08YWDW58T"><span class="button-text">Shop Now</span></a>
                                    </button>
                                </div>
                            </section>
                        </ScrollAnimation>
                        <hr className='style-two' />
                        <ScrollAnimation animateIn='fadeIn'>
                            <section className='product-6'>
                                <ScrollAnimation animateIn='bounceInRight'>
                                    <img src={jaggeryPowderSachet} alt="" />
                                </ScrollAnimation>
                                <div class="product-info">
                                    <h2>JAGGERY POWDER SACHETS</h2>
                                    <p>If you require a smaller packing of jaggery powder, which you could finish in a go, we have a solution for it. We can provide you with a box of 60 jaggery powder sachets in 1 kg packing.</p>
                                </div>
                                <div class="shop-now">
                                    <button class="learn-more btn">
                                        <span class="circle" aria-hidden="true">
                                            <span class="icon arrow"></span>
                                        </span>
                                        <a target="_blank" href="https://www.amazon.in/VEDNUTRI-SUGARCANE-JAGGERY-POUCHES-SHAKKAR/dp/B093FCYJBX/ref=sr_1_4_sspa?keywords=Vednutri&qid=1642959"><span class="button-text">Shop Now</span></a>
                                    </button>
                                </div>
                            </section>
                        </ScrollAnimation>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Home
