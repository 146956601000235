import React from 'react'
import './About.css'

function About() {
    return (
        <div className='about-container'>
            <section className='about-section-1'>
                <div class="page-info-section-1">
                    <h1>ABOUT US</h1>
                    <p>Vednutri was incorporated in 2018, with an aim to manufacture high hygiene and supreme quality products. We promise to provide you with authentic sugarcane jaggery without any additional chemicals or sweeteners.We manufacture jaggery from our own sugarcane farms. We possess the in-house capability of developing innovative manufacturing solutions. There is a provision of cold storage in order to prolong the shelf life of our products.</p>
                </div>
                <div class="about-img">
                    <div class="card">
                        <div class="card_part carousel-1-1">
                        </div>

                        <div class="card_part carousel-1-2">

                        </div>

                        <div class="card_part carousel-1-3">
                        </div>

                        <div class="card_part carousel-1-4">
                        </div>

                    </div>
                </div>
            </section>
            <section className='about-section-2'>
                <div class="page-info-section-2">
                    <h1>OUR PRODUCTS</h1>
                    <p>Jaggery is a staple food, especially during the winter months. Jaggery has various significant health benefits including its ability to act as a detoxing agent. Jaggery is replete with various minerals like iron, magnesium, calcium, potassium, and antioxidants. Traditional Punjabi cuisine is incomplete without jaggery(gur). You could add it to your tea, coffee, cakes, or enjoy it as it is. This winter try our natural organic delicacy. TRY IT ONCE AND YOU'LL ASK FOR MORE!</p>
                </div>
                <div class="about-img">
                    <div class="card">
                        <div class="card_part carousel-2-1">
                        </div>

                        <div class="card_part carousel-2-2">
                        </div>

                        <div class="card_part carousel-2-3">
                        </div>

                        <div class="card_part carousel-2-4">
                        </div>

                    </div>
                </div>
            </section>

        </div>
    )
}

export default About
