import './App.css'
import Navbar from './Navbar'
import Footer from './Footer'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import React from 'react'
import Home from './pages/Home'
import { useEffect, useState } from 'react'
import Logo from './assets/logo.jpg'
import { HashLoader } from 'react-spinners'
import About from './pages/About'
import Contact from './pages/Contact'



function App() {
  const [isLoading, setLoading] = useState(true);

  function fakeRequest() {
    return new Promise(resolve => setTimeout(() => resolve(), 2000));
  }

  useEffect(() => {
    fakeRequest().then(() => {
      if (true) {
        setLoading(!isLoading);
      }
    });
  }, []);
  return isLoading !== true ? (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
        </Switch>
        <Footer />
      </Router>
    </div>
  )
    :
    <div className="main-loader-container">
      <div class="img-container">
        <img class="loading-img" src={Logo} alt="" />
        <div class="overlay"></div>
      </div>
      <div class="loader">
        <HashLoader color="#017e00" className="main-loader" />

      </div>
    </div>
}

export default App;
