import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import './Contact.css'

function Contact() {
    function ani() {
        document.getElementById('plane').className = 'animation';
    }
    function anitwo() {
        document.getElementById('bg').className = 'animation2';
    }

    return (
        <div className="contact-container">
            <form target="_blank" action="https://formsubmit.co/d789a24996bd62e0eb20313cf53fc465 " method="POST">
                <div class="contact-form">
                    <h1>Get In Touch!</h1>
                    <div class="form__group field">
                        <input type="text" class="form__field" placeholder="Email" name="email" autoComplete="off" id='email' required />
                        <label for="email-login" class="form__label">Email</label>
                    </div>
                    <div class="form__group field">
                        <input type="text" class="form__field" placeholder="Phone Number" name="phn" autoComplete="off" id='phn' required />
                        <label for="phn" class="form__label">Phone Number</label>
                    </div>
                    <div class="form__group field">
                        <textarea type="text" class="form__field" placeholder="Message" name="msg" autoComplete="off" id='msg' required />
                        <label for="msg" class="form__label">Message</label>
                    </div>
                    <button type='submit' className="send-btn">Send<img src="https://i.cloudup.com/gBzAn-oW_S-2000x2000.png" width="32px" height="32px" id="plane" /></button>
                </div>
            </form>
            <div className='alt-contact'>
                <h1 style={{ color: "white" }}>OR</h1>
                <h3 style={{ color: "white" }}>reach us at</h3>
                <ScrollAnimation animateIn='bounceInLeft'>
                    <tr>
                        <div className='section-1-contact'>
                            <div>
                                <td>
                                    <h1 style={{ color: "green" }}><i class="fas fa-envelope-open-text"></i></h1>
                                    <h2>EMAIL</h2>
                                    pratapsheelnutricare@gmail.com
                                </td>
                            </div>
                            <br/>
                            <div>
                                <td>
                                    <h1 style={{ color: "green" }}><i class="fas fa-phone"></i></h1>
                                    <h2>PHONE</h2>
                                    +917009324020
                                </td>
                            </div>
                        </div>
                    </tr>
                </ScrollAnimation>
                <br/>
                <ScrollAnimation animateIn='bounceInRight'>
                    <tr>
                        <div className='section-2-contact'>
                            <td>
                                <h1 style={{ color: "green" }}><i class="fas fa-map-marked-alt"></i></h1>
                                <h2>ADDRESS</h2>
                                Vill. Adamwal Garhi, V&PO Hariana, Distt. Hoshiarpur 144208
                            </td>
                            <br/>
                            <td>
                                <h1 style={{ color: "green" }}><i class="fab fa-instagram"></i></h1>
                                <h2>INSTAGRAM</h2>
                                The_jaggery_store
                            </td>
                        </div>
                    </tr>
                </ScrollAnimation>
            </div>
        </div >
    )
}

export default Contact
